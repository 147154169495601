import React, { useEffect, useState } from 'react';
import { Col } from "react-bootstrap";

//importing ability to navigate
import { useNavigate } from "react-router";


//font awesome icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleUser } from "@fortawesome/free-solid-svg-icons";
import { faMessage } from "@fortawesome/free-regular-svg-icons";

import ChatComponent from "../chatComponent";

export default function Footer() {
    const navigate = useNavigate();
    const [isChatOpen, setIsChatOpen] = useState(false);

    const toggleChat = () => {
        setIsChatOpen(!isChatOpen);
    };

    return (
        <div id="footer" className="d-flex justify-content-center align-content-center">
                <Col className="bottomNavBarAccountButtonContainer">
                <FontAwesomeIcon
                    icon={faCircleUser}
                    className="bottomNavBarNavigateButton"
                    onClick={() => {
                    navigate("/account-page");
                    }}
                />
                </Col>
                <div>

                </div>
                <div className="bottomNavBarMessageButtonContainer">
                <FontAwesomeIcon
                    icon={faMessage}
                    className="bottomNavBarNavigateButton"
                    onClick={() => {
                    toggleChat()
                    console.log("go to message page");
                    }}
                />
                </div>

                {isChatOpen && (
                    <ChatComponent/>
                )}
        </div>
    )
}