//import tool to make timestamp
import moment from 'moment-timezone';

//importing fetch requests to send log to server
import { sendLogToServer } from '../fetchRequests/loggingFetch';

//importing tools to get user device information for log
import {
    isMobile,
    isTablet,
    isDesktop,
    deviceType,
    browserName,
    browserVersion,
    engineName,
    osName,
    osVersion,
    mobileVendor,
    mobileModel,
    deviceDetect
} from 'react-device-detect';


//===================================FORMATTING AND RESOURCE GATHERING===================================//

//getting the session token cookie
const getCookie = (name) => {
    const cookies = document.cookie.split("; ");
    for (let cookie of cookies) {
      const [key, value] = cookie.split("=");
      if (key === name) {
        return value;
      }
    }
    return undefined;
  };
  

//getting timestamp for log
const getTimeStamp = () => {
    return moment().tz("America/New_York").format("YYYY-MM-DD HH:mm:ss");
};


//formatting the log message to send to server
const formatLogMessage = (level, message) => {
    const timestamp = getTimeStamp();
    const formattedMessage = {
        time_stamp: timestamp,
        level:level.toUpperCase(),
        message:message
    }
    return formattedMessage;
};


//===================================CREATING A NEW LOG TO SEND TO SESSION===================================//

export const createLog = async (data) => {

    //token used to identify log session
    const sessionId = getCookie("session_token");

    const dataToLog = {
        content:data,
    }

    //formatting data
    const formattedLog = JSON.stringify(formatLogMessage('info', dataToLog));

    //calling function that makes fetch sending log to server
    await sendLogToServer(formattedLog, sessionId)

};


//===================================CREATING A NEW ERROR LOG TO SEND TO SESSION===================================//

export const createErrorLog = async (data) => {
    
    //token used to identify log session
    const sessionId = getCookie("session_token");

    const dataToLog = {
        content:data,
    }

    //formatting data
    const formattedLog = JSON.stringify(formatLogMessage('error', dataToLog));

    //calling function that makes fetch sending log to server
    await sendLogToServer(formattedLog, sessionId)

};


//===================================INITIALIZING LOG SESSION===================================//

export const getLog = async (data) => {

    //getting device data for initial log
    const dataToLog = {
        content:data,
        device_type: deviceType,
        browser_name:browserName, 
        browser_version:browserVersion, 
        engine_name: engineName,
        os_name:osName,
        os_version:osVersion,
        mobile_vendor: mobileVendor,
        mobile_model: mobileModel
    }

    //formatting log message
    const formattedLog = JSON.stringify(formatLogMessage('info', dataToLog));

    return formattedLog
}