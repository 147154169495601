

//getting the users current location
export const getLocation = () => {

    //settting options for geolocation retrieval
    const geolocationOptions = {
      enableHighAccuracy: true,
      maximumAge: 25,
      timeout: 10000,
    };

    return new Promise((resolve, reject) => {
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(success, error, geolocationOptions);
      } else {
        reject("Geolocation not supported");
      }
  
      function success(position) {
        //filering inaccurate geolocation
        if (position.coords.accuracy <= 50) {
          const location = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          resolve(location);
        } else {
          console.log("Inaccurate location from getCurrentPosition");
          resolve(null);
        }
      }
  
      function error() {
        console.log("Unable to get your location");
        resolve(null);
      }
    });
  };