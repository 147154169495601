import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router";

//font awesome icons
import { faCircleLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//images used on page
import baseUserIcon from "../../images/user-icon.png";
import background from "../../images/palmtreesdark.png";

//importing the logo component
import Logo from '../Logo';

//importing use cookie to process browser cookies
import { useCookies } from "react-cookie";

//importing child modal component
import UserEditModal from "../Modals/UserEditModal.jsx";
import Footer from '../HomePageComponents/Footer.jsx';

//importing fetch function
import { retrieveUserInformation } from "../../fetchRequests/accountPageFetch.js";

//importing shared styling function
import {
  failToast,
} from "../../shared/sharedFunctions.js"

//account page component where the user can see their account information
export default function AccountPage() {
  //ability to navigate
  const navigate = useNavigate();
  //ability to use browser cookies
  const [cookie, setCookie, removeCookie] = useCookies();

  //use state to show or hide editing modal
  const [showEditModal, setShowEditModal] = useState()
  //use state for containing user account information
  const [userData, setUserData] = useState({
    name:"",
    user_name:"",
    phone:"",
    email:"",
    account_plan:"",
    bike:{
      brand:"",
      model:"",
    }
  })


  //===================================GETTING USER ACCOUNT INFORMATION===================================//

  const getUserInformation = async () => {

    //calling function to fetch user data
    const serverResponse = await retrieveUserInformation(cookie.accessToken)

    if(!serverResponse.data){
      failToast(serverResponse.message)
      //navigate("/")
      return
    }

    //parsing recieved data
    const dataFromFetch = serverResponse.data.user_data
    const {name, user_name, phone, email, account_plan, bike} = dataFromFetch

    //updating state to contain users retrieved data
    setUserData({
      name:name,
      user_name:user_name,
      phone:phone,
      email:email,
      account_plan:account_plan,
        bike:{
        brand:bike.brand,
        model:bike.model,
      }
    })
  }
  
  //calling get user information on page load
  useEffect(() =>{
    getUserInformation()
  }, [])


  //===================================COMPONENT FUNCTIONS===================================//

  //called if user presses logout button
  const handleLogout = () => {
    removeCookie("token");
    navigate("/");
  }


  //===================================JSX RETURN===================================//

  return (
    <div
      className="accountPageContainer"
      style={{ backgroundImage: `url(${background})` }}
    >
      <UserEditModal 
        showEditModal={showEditModal}
        setShowEditModal={setShowEditModal}
        userData={userData}
        getUserInformation={getUserInformation}>
      </UserEditModal>
              <FontAwesomeIcon
                icon={faCircleLeft}
                className="headerIconLeft"
                onClick={() => {
                  navigate("/home");
                }}
              />
                <Logo
                  style={{width: '100px'}}
                  onClick={() => {
                  navigate("/home");
                }}
                />
      <div className="accountPageContent">
          <div className="iconAndUsernameField">
                <img
                  src={baseUserIcon}
                  className="accountUserIcon"
                  alt="user icon"
                ></img>
            <div className="userNameContainer">
              <Row className="accountUserSubName poppins-extralight">
                User Name
              </Row>
              <Row className="accountUserName poppins-medium">
                {userData.user_name}
              </Row>
            </div>
        </div>
          <button
            onClick={() => {setShowEditModal(true);
            }}
            className="accountPageButtons1 poppins-semibold"
          >
            Edit Account Information
          </button>
        <div className="accountUserInformationContainer">
            <div className="accountUserInfoSubText poppins-extralight">
              Name
            </div>
            <div className="accountUserInfoText poppins-regular">
              {userData.name}
            </div>
            <div className="accountUserInfoSubText poppins-extralight">
              Mobile Phone Number
            </div>
            <div className="accountUserInfoText poppins-regular">
              {userData.phone}
            </div>
            <div className="accountUserInfoSubText poppins-extralight">
              Email
            </div>
            <div className="accountUserInfoText poppins-regular">
              {userData.email}
            </div>
            <div className="accountUserInfoSubText poppins-extralight">
              Account Plan
            </div>
            <div className="accountUserInfoText poppins-regular">
              {userData.account_plan}
            </div>
            <div className="accountUserInfoBottomText poppins-regular">
              Your Bike
            </div>
          <div className="bikeInformationContainer">
            <Col className="brandInformationContainer">
              <div className="accountUserInfoSubText poppins-extralight">
                Brand
              </div>
              <div className="accountUserInfoText poppins-regular">
                {userData.bike.brand}
              </div>
            </Col>
            <Col className="modelInformationContainer">
              <div className="accountBikeInfoSubText poppins-extralight">
                Model
              </div>
              <div className="accountBikeInfoText poppins-regular">
                {userData.bike.model}
              </div>
            </Col>
          </div>
        </div>
          <button
            onClick={() => {
              console.log(" go to user edit page");
            }}
            className="accountPageButtons2 poppins-semibold"
          >
            Upgrade Plan
          </button>
        <div onClick={() => {handleLogout()}} className="accountLinkText poppins-medium">
          Log Out
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
}
