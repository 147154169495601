import React, {useEffect, useState}from "react";

//importing react boostrap component
import Modal from "react-bootstrap/Modal";

//importing icons to use on page
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//importing ability to use cookies
import { useCookies } from "react-cookie";

//importing fetch request to update users account information
import { 
    updateUserInformation 
} from "../../fetchRequests/accountPageFetch";


//importing shared styling functions
import {
    handleMouseOver,
    handleMouseOut,
    failToast,
    successToast
} from "../../shared/sharedFunctions"

export default function UserEditModal({
    showEditModal,
    setShowEditModal,
    userData,
    getUserInformation
}) {

    //ability to use cookies
    const [cookie, setCookie, removeCookie] = useCookies();

    //use state to hold users account information
    const [updateUserForm, setUpdateUserForm] = useState({
        name:"",
        user_name:"",
        phone:"",
        email:"",
        account_plan:"",
        bike:{
            brand:"",
            model:"",
        }
    })

    //use effect setting the update form to
    //users existing account information
    useEffect(() => {
        setUpdateUserForm({
            name:userData.name,
            user_name:userData.user_name,
            phone:userData.phone,
            email:userData.email,
            account_plan:userData.account_plan,
            brand:userData.bike.brand,
            model:userData.bike.model,
        })
    },[userData])

    //function used for updating the 
    //account update form
    const updateForm = (value) => {
        return setUpdateUserForm((prev) => {
          return {...prev, ...value}
        })
      }

    //===================================USER SUBMITTING ACCOUNT UPDATE===================================//\

    const submitUpdates = async () =>{

        //calling function which sends fetch to update users account information
        const serverResponse = await updateUserInformation(cookie.accessToken, updateUserForm)

        if(!serverResponse.data){
            failToast(serverResponse.message)
            //navigate("/")
            return
          }

        successToast(serverResponse.message)
        setShowEditModal(false)
        getUserInformation()
        
    }

  //===================================JSX RETURN===================================//

  return (
    <>
      <Modal
        size="sm-8"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showEditModal}
        backdrop={false}
        keyboard={false}
        style={{
          maxWidth: "800px",
          maxHeight: "900px",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Modal.Header className="modalHeader">
            <div style={{textAlign:"left"}}>
                <Modal.Title className="poppins-extrabold">
                EDIT YOUR ACCOUNT INFORMATION
                </Modal.Title>
            </div>
            {/* canceling service order if modal is closed */}
            <div className="confirmModalCloseContainer">
                <FontAwesomeIcon
                className="modalCloseButton"
                icon={faTimes}
                color="grey"
                onClick={() => {
                    //setMechanicInfo({ time: null, distance: null, name: null });
                    setShowEditModal(false);
                }}
                />
            </div>
        </Modal.Header>
          <Modal.Body>
                <div style={{
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"center",
                    textAlign:"center",
                    marginBottom:"10px"}}>
                  <div className="form w-100 d-grid gap-3 p-4" style={{maxWidth:"350px"}}>
                    <div className="poppins-bold">Name</div>
                    <div className="">
                        <input
                            id="name"
                            className="form-control"
                            type="text"
                            placeholder='name'
                            value={updateUserForm.name}
                            onChange={(e) => updateForm({name: e.target.value})}>
                        </input>
                    </div>
                    <div className="poppins-bold">Phone Number</div>
                    <div className="">
                        <input
                            id="phone"
                            className="form-control"
                            type="text"
                            placeholder='phone'
                            value={updateUserForm.phone}
                            onChange={(e) => updateForm({phone: e.target.value})}>
                        </input>
                    </div>
                    <div className="poppins-bold">Bike Brand</div>
                    <div className="">
                        <input
                            id="brand"
                            className="form-control"
                            type="text"
                            placeholder='brand'
                            value={updateUserForm.brand}
                            onChange={(e) => updateForm({brand: e.target.value})}
                            >
                        </input>
                    </div>
                    <div className="poppins-bold">Bike Model</div>
                    <div className="">
                        <input
                            id="model"
                            className="form-control"
                            type="text"
                            placeholder='model'
                            value={updateUserForm.model}
                            onChange={(e) => updateForm({model: e.target.value})}
                            >
                        </input>
                    </div>
                    <button
                        className="btn btn-primary"
                        onClick={() => {submitUpdates()}}
                        onMouseOver={handleMouseOver}
                        onMouseOut={handleMouseOut}
                        type="button">Submit Information
                    </button>
                    <button
                        className="btn btn-secondary"
                        onClick={() => {setShowEditModal(false)}}
                        onMouseOver={handleMouseOver}
                        onMouseOut={handleMouseOut}
                        type="button">Cancel
                    </button>
                </div>
                </div>
          </Modal.Body>      
      </Modal>
    </>
  );
}
