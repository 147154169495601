//importing axios to make fetch request
import axios from "axios";

//importing fetch requests to create normal and error logs
import { 
  createLog,
  createErrorLog
} from "../shared/loggingFunctions";

//setting the base endpoint to send fetch api's too
const API_URL = 'https://bike911-server.onrender.com/map';


//===================================GETTING MECHANICS FOR DATABASE===================================//


//------FUNCTIONAL------//
export const mechanicsForMap = async (accessToken, user_location) => {

  //data for api logging
  const endpoint = "/map/map-mechanics"
  const method = "POST"

  //setting header to inlude authorization token
  const headers = {
    'Authorization': `Bearer ${accessToken}`,
    'Content-Type': 'application/json' // Optional, depending on your API requirements
  };

  try{ 

    //CREATE INITIAL LOG//
    await createLog({
      endpoint:endpoint,
      method:method,
      type:"SENDING API FETCH",
      request_body:user_location
    })

    //sending fetch request
    const requestSent = await axios.post(`${API_URL}/map-mechanics`,user_location, {headers})

    //if checking that response was succesful
    if(requestSent.status === 200){
      const serverResponse = {
        data:requestSent.data.data.mechanics,
        message:requestSent.data.message,
      }

      //CREATE SUCCESS LOG//
      await createLog({
        endpoint:endpoint,
        method:method,
        type:"FETCH RESPONSE",
        response_data:serverResponse,
      })

      //returning data
      return serverResponse
    }

  }catch(error){

    //if checking that proper error data was recieved
    if (error.response && error.response.data) {
      const message = error.response.data.message
      const serverResponse = {
          message:message
      }

      //CREATE ERROR LOG//
      await createErrorLog({
        endpoint:endpoint,
        method:method,
        type:"FETCH ERROR",
        error_data:serverResponse,
      })

      return serverResponse
    }
  }
}

//===================================GETTING CENTER OF MAP===================================//

export const callForCenter = async (accessToken, idWorkOrder) => {

  //data for api logging
  const endpoint = "/map/center-view"
  const method = "GET"

  //headers of fetch set to include the access token
  const headers = {
    'Authorization': `Bearer ${accessToken}`,
    'Content-Type': 'application/json' // Optional, depending on your API requirements
  };

  try{ 

    //CREATE INITIAL LOG//
    await createLog({
      endpoint:endpoint,
      method:method,
      type:"SENDING API FETCH",
      request_query:idWorkOrder
    })

    //sending fetch request
    const requestSent = await axios.get(`${API_URL}/center-view?id=${idWorkOrder}`, {headers})

    //if statement checking for succesful response
    if(requestSent.status === 200){
      const serverResponse = {
        data:requestSent.data.data,
        message:requestSent.data.message,
      }

      //CREATE SUCCESS LOG//
      await createLog({
        endpoint:endpoint,
        method:method,
        type:"FETCH RESPONSE",
        response_data:serverResponse,
      })

      //retunring date
      return serverResponse
    }

  }catch(error){
    //if statement checking for proper error data
    if (error.response && error.response.data) {
      const message = error.response.data.message
      const serverResponse = {
          message:message
      }

      //CREATE ERROR LOG//
      await createErrorLog({
        endpoint:endpoint,
        method:method,
        type:"FETCH ERROR",
        error_data:serverResponse,
      })

      return serverResponse
    }
  }
}
