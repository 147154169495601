import React, { useState, useCallback, useEffect } from 'react';
import { Form, InputGroup, Button } from 'react-bootstrap';
import io from 'socket.io-client';

// const SOCKET_SERVER_URL = 'https://bike911-server.onrender.com:10001';
// const socket = io('https://bike911-server.onrender.com:10001');

export default function ChatComponent() {
  const [text, setText] = useState("");
  const [name, setName] = useState("Dave");
  const [messages, setMessages] = useState([]);
  const setRef = useCallback( node => {
    if(node){
      node.scrollIntoView({smooth:true})  
    }
  }, [])



  // const sendMessage = (e) => {
  //   e.preventDefault();

  //   socket.emit("send_message", {message:"Hello", sender:"John"});
  // }


  // useEffect(() => {
  //   socket.on('message', (message) => {
  //     setMessages((messages) => [...messages, message]);
  //   });
  // }, []);


  const handleSubmit = (e) => {
    e.preventDefault();
    
    // if (name && text) {
    //   socket.emit('sendMessage', { name, text });
    //   setText("");
    // }

  };


  // const messageFromSender = (message) => {
  //   if(message.sender === "John"){
  //       return true
  //   }

  //   return false
  // };

  // const populateMessages = () => {
  //     return messages.map((message, index) => {
  //       const lastMessage = messages.length -1 === index
  //           return(
  //               <div 
  //                   ref={lastMessage ? setRef : null}
  //                   key={index} className={`my-1 d-flex flex-column ${messageFromSender(message) ? 'align-self-end' : ''}`}>
  //                   <div
  //                   className={`rounded px-2 py-1 ${messageFromSender(message) ? 'bg-primary' : 'bg-secondary'}`}
  //                   >
  //                   {message.text}
  //                   </div>
  //                   <div
  //                       className={`text-muted small ${messageFromSender(message) ? 'text-right' : ''}`}
  //                   >{messageFromSender(message) ? 'You' : message.sender}</div>
  //               </div>
  //           )
  //       });
  //   };


  return (
    <div 
        className="d-flex flex-column flex-grow-1" 
        style={{
            position: "fixed",
            bottom: "110px", /* Adjust based on footer height */
            right: "20px", /* Adjust based on desired positioning */
            width: "300px", /* Adjust based on desired size */
            height: "400px", /* Adjust based on desired size */
            backgroundColor: "white", /* or any background color you prefer */
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
            borderRadius: "5px",
            zIndex: 1321312321312313
        }}
    >
      <div className="flex-grow-1 overflow-auto">
        <div 
            className=' 
                d-flex 
                flex-column 
                align-items-start 
                justify-content-end 
                px-3
            '
        >
            {/* {populateMessages()} */}

        </div>
      </div>
      <Form 
      // onSubmit={sendMessage}
      >
        <Form.Group className="m-2">
          <InputGroup>
            <Form.Control
              as="textarea"
              required
              value={text}
              onChange={(e) => setText(e.target.value)}
              style={{ height: "75px", resize: "none" }}
            />
            <Button type="submit">Send</Button>
          </InputGroup>
        </Form.Group>
      </Form>
    </div>
  );
}
