//importing node packages
import React from "react";
import Form from 'react-bootstrap/Form';

//Bike information component used in the header account info sidebar
function FormMenu({userData}) {
    return (
        <div className="p-4 d-block">
            <h3 className="my-3">Your Bike</h3>
            <Form.Group className="mb-3" controlId="null">
                <Form.Label htmlFor="brandName" className="w-100">Brand</Form.Label>
                <p htmlFor="BrandNameInput" className="h5">{userData.bike.brand}</p>
            </Form.Group>
            <Form.Group className="mb-3" controlId="null">
                <Form.Label htmlFor="nodelName" className="w-100">Model</Form.Label>
                <p htmlFor="modelNameInput" className="h5">{userData.bike.model}</p>
            </Form.Group>
        </div>
    );
}

export default FormMenu;