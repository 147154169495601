//importing react tools
import React,  {useEffect} from 'react'

//importing necessary google map node packages
import { GoogleMap, MarkerF, Polyline, useJsApiLoader } from "@react-google-maps/api";

//importing images used on map component
import userIcon from "../../images/icons8-dot-48.png";
import mechanicIcon from "../../images/svg/icn_tools.svg";

//The map component that is rendered on the home page
export default function GoogleMapComponent({
    usersUpdatedLocation, 
    existingPolyline,
    mechanics, 
    setMapInstance,
    userLocation
}) {

  //===================================COMPONENT FUNCTIONS===================================//

  //initializing map instance
    const handleMapLoad = (map) => {
        setMapInstance(map);
    };

    //retrieving google map key to use map component
    const googleMapKey = process.env.REACT_APP_MAP_KEY

    //confirming map is loaded
    const { isLoaded } = useJsApiLoader({
        id: "google-map-script",
        googleMapsApiKey: googleMapKey,
    });


  //===================================JSX RETURN===================================//

  return (
    <div>
      {isLoaded && window.google && (
          <GoogleMap
          zoom={15}
          center={userLocation}
          onLoad={handleMapLoad}
          options={{
              fullscreenControl: false,
              mapTypeControl: false, 
              zoomControl: false,
              streetViewControl: false
          }}
             //onClick={onMapClick}
          >
            <MarkerF
              position={{
                lat: usersUpdatedLocation.lat,
                lng: usersUpdatedLocation.lng,
                key: "User"
              }}
              icon={userIcon}
              />
            {existingPolyline && (
              <Polyline
              path={existingPolyline}
              key={1}
              options={{
                strokeColor: "#38B44F",
                strokeOpacity: 1,
                strokeWeight: 7
              }}
              />
            )}
            {mechanics && mechanics.length > 0 && mechanics.map((mechanic) => {
              // Add checks to ensure mechanic and mechanic.location are defined
              if (mechanic && mechanic.location) {
                return (
                  <MarkerF
                    key={`${mechanic.location.lat}-${mechanic.location.lng}`}
                    position={{ lat: mechanic.location.lat, lng: mechanic.location.lng }}
                    icon={mechanicIcon}
                  />
                );
              }
              return null;
            })}
          </GoogleMap>
        )}
    </div>
  )
}
