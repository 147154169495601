//toastify node package used to make toast alerts
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//===================================HANDLING COLOR CHANGE ON BUTTON CLICK===================================//

//function to recolor buttons on mouse over
export const handleMouseOver = (e) => {
        e.target.style.backgroundColor = 'black';
        e.target.style.color = 'white';
    };

//function used to recolor button on mouse out
export const handleMouseOut = (e) => {
        e.target.style.backgroundColor = 'orange';
    };

//===================================HANDLING TOASTERS===================================//

//universal function to create toast alert on success
export const successToast = (message) =>{
    toast.success(message, {
        autoClose: 5000,
        closeButton: true,
      });
}

//universal function to create toast alert on failure
export const failToast = (message) =>{
    toast.error(message, {
        autoClose: 5000,
        closeButton: true,
        position: "bottom-center",
      });
}