import React from 'react';
import { Col } from "react-bootstrap";

//importing the required images
import baseUserIcon from "../../images/user-icon.png"
import facebookIcon from "../../images/svg/icn_facebook.svg";
import instagramIcon from "../../images/svg/icn_instagram.svg";
import linkedInIcon from "../../images/svg/icn_linkedin.svg";


//JSX component to display mechanic information in sidebar is there is an active work order
export default function TechTracker({workOrderData}) {

    return (
        <div className=" tech-tracker mb-2 text-dark w-100">
            <div className="sidebarMechanicInfoContainer">
            {/* conditionally rendering the displayed mechanic information if there is a current active call */}
            {workOrderData.travel_time !== null && workOrderData.distance !== null ? (
            <div className="sidebarActiveInfoContainer">
                <div>
                <div className="sidebarMechanicIconContainer">
                    <img
                    src={baseUserIcon}
                    className="sidebarMechanicIcon"
                    alt="mechanic icon"
                    ></img>
                </div>
                </div>
                <Col className="sidebarActiveMechanicInfoContainer">
                <div className="sidebarActiveMechanicText poppins-medium">
                    Your Mechanic: {workOrderData.mechanic_name}
                </div>
                <div className="sidebarActiveMechanicText poppins-medium">
                    Distance: {workOrderData.distance}
                </div>
                <div className="sidebarActiveMechanicText poppins-medium">
                    Time: {workOrderData.travel_time}
                </div>
                <div className="sidebarActiveMechanicText poppins-medium">
                    Phone: {workOrderData.mechanic_phone}
                </div>
                </Col>
            </div>
            ) : (
            <>
                <div className="sidebarNonActiveMechanicText poppins-semibold">
                No Active Calls
                </div>
                {/* Add additional content or leave empty based on your requirements */}
            </>
            )}
        </div>
                <div className="form d-flex justify-content-center py-1">
                    <a 
                    href="https://www.facebook.com/share/W4isyEhkNL72ugRs/?mibextid=A7sQZp" 
                    target="_blank" 
                    rel="noopener noreferrer"
                    >
                    <img
                        src={facebookIcon}
                        className="sidebarSocialMediaIcons"
                        alt="facebook logo"
                    />
                    </a>
                    {/* <img
                        src={instagramIcon}
                        className="sidebarSocialMediaIcons"
                        alt="instagram logo"
                    ></img>
                    <img
                        src={linkedInIcon}
                        className="sidebarSocialMediaIcons"
                        alt="linked in logo"
                    ></img> */}
                </div>
        </div>
    );
}