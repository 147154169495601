//importing axios to make fetch request
import axios from "axios";

//setting the base endpoint to send fetch api's too
const API_URL = 'https://bike911-server.onrender.com/token';

//===================================USER LOGGING IN===================================//

//------FUNCTIONAL------//
export const fetchLoginVerify = async (accessToken) => {

    //setting headers of fetch request to include the access token
    const headers = {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json' // Optional, depending on your API requirements
      };

    try{

        //sending fetch request
        const requestSent = await axios.get(`${API_URL}/validate-token`, {headers})
    
        //if checking for succesful response
        if(requestSent.status === 200){
        
            const serverResponse = {
                data:requestSent.data.data.time,
                message:requestSent.data.message,
            }

            //returning data
            return serverResponse
        }

    }catch(err){

        //if checking that proper error data was recieved
        if (err.response && err.response.data) {
            const message = err.response.data.message
            const serverResponse = {
                message:message
            }
            return serverResponse
        } 
    }
}
