import React, { useState}from "react";

//importing react modal boostrap component
import Modal from "react-bootstrap/Modal";

//importing loading modal child component
import LoadingModal from "./LoadingModal";

//importing icons for component
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//importing ability to use cookies
import { useCookies } from "react-cookie";

//importing shared styling functions
import {
    handleMouseOver,
    handleMouseOut,
    failToast,
    successToast
} from "../../shared/sharedFunctions"

//importing fetch to create work order
import { createWorkOrderFetch } from "../../fetchRequests/workOrderFetches";

export default function WorkDetailsModal({
    showWorkDetailsModal,
    setShowWorkDetailsModal,
    usersUpdatedLocation,
    helpCallMade
}) {

  //ability to use cookies
  const [cookie, setCookie, removeCookie] = useCookies();

  //use state holding work requested by user
  const [workRequestDetails, setWorkRequestDetails] = useState(null)

  //use state to parse wether the input field or buttons are active
  const [inputActive, setInputActive] = useState(false);
  
  //use state to determine wether or not to show the loading modal
  const [loading, setLoading] = useState(false)


  //===================================USER CREATING WORK ORDER===================================//\

  const createWorkOrder = async () => {

    setLoading(true)

    const locationSent = {
      updateLat: usersUpdatedLocation.lat,
      updateLng: usersUpdatedLocation.lng,
      service_requested:workRequestDetails
    }

    //calling function which will send fetch to create a new work order
    const serverResponse = await createWorkOrderFetch(cookie.accessToken, locationSent)

    setWorkRequestDetails(null)
          
    setLoading(false)

    if(!serverResponse.data){
      failToast(serverResponse.message)
      setShowWorkDetailsModal(false)
      return
    }

    successToast(serverResponse.message)
    setCookie("work_order_id", serverResponse.data, {path:'/'})
    helpCallMade()
    return
  };

  //function to handle a user selecting a radio button
  const handleRadioChange = (value) => {
      setWorkRequestDetails(value);
      setInputActive(false); // Clear input field active state
  };
  
  //function to handle a user typing in the input field
  const handleInputChange = (e) => {
      setWorkRequestDetails(e.target.value);
      setInputActive(true); // Set input field active state
  };


  //===================================JSX RETURN===================================//

  return (
    <>
      {loading ? (
          <LoadingModal message={"Creating work order..."}/>
        ) : (
        <Modal
          size="sm-8"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={showWorkDetailsModal}
          backdrop={false}
          keyboard={false}
          style={{
            maxWidth: "800px",
            maxHeight: "900px",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Modal.Header className="modalHeader">
              <div style={{textAlign:"left"}}>
                  <Modal.Title className="poppins-extrabold">
                  WHAT CAN WE HELP YOU WITH?
                  </Modal.Title>
              </div>
              {/* canceling service order if modal is closed */}
              <div className="confirmModalCloseContainer">
                  <FontAwesomeIcon
                  className="modalCloseButton"
                  icon={faTimes}
                  color="grey"
                  onClick={() => {
                      //setMechanicInfo({ time: null, distance: null, name: null });
                      setShowWorkDetailsModal(false);
                  }}
                  />
              </div>
          </Modal.Header>
            <Modal.Body>
                  <div style={{
                      display:"flex",
                      alignItems:"center",
                      justifyContent:"center",
                      textAlign:"center",
                      marginBottom:"10px"}}>
                    <div className="form w-100 d-grid gap-3 p-4" style={{maxWidth:"350px"}}>
                      <div className="">
                          <label>
                              <input
                                  type="radio"
                                  name="workRequest"
                                  value="flat tire"
                                  checked={workRequestDetails === "flat tire"}
                                  onChange={() => handleRadioChange("flat tire")}
                              />
                              Flat Tire
                          </label>
                      </div>
                      <div className="">
                          <label>
                              <input
                                  type="radio"
                                  name="workRequest"
                                  value="dead battery"
                                  checked={workRequestDetails === "dead battery"}
                                  onChange={() => handleRadioChange("dead battery")}
                              />
                              Dead Battery
                          </label>
                      </div>
                      <div className="poppins-bold">Other</div>
                      <div className="">
                          <input
                              id="model"
                              className={`form-control ${inputActive ? 'active' : ''}`}
                              type="text"
                              placeholder=''
                              //value={workRequestDetails}
                              //onChange={(e) => handleInputChange(e.target.value)}
                              onChange={handleInputChange}
                              onFocus={() => setInputActive(true)}
                              onBlur={() => setInputActive(false)}
                              />
                      </div>
                      <button
                          className="btn btn-primary"
                          onClick={() => {createWorkOrder()}}
                          onMouseOver={handleMouseOver}
                          onMouseOut={handleMouseOut}
                          type="button">Submit Information
                      </button>
                      <button
                          className="btn btn-secondary"
                          onClick={() => {setShowWorkDetailsModal(false)}}
                          onMouseOver={handleMouseOver}
                          onMouseOut={handleMouseOut}
                          type="button">Cancel
                      </button>
                  </div>
                  </div>
            </Modal.Body>
        </Modal>
        )}
    </>
  );
}
