import React from "react";
import '../stylesheets/main.scss';

//importing necessary images
import LogoBrand from '../images/svg/logo_bike911-bkg.svg';

//logo component that can be used throughout the website
function Logo({ style }) {
    return (
        <img className="logo" src={LogoBrand} alt="Bike 911" style={style} />
            );
    }

export default Logo;