//importing axios to make fetch request
import axios from "axios";

//importing fetch requests to create normal and error logs
import { 
  createLog,
  createErrorLog
} from "../shared/loggingFunctions";

//setting the base endpoint to send fetch api's too
const API_URL = 'https://bike911-server.onrender.com/auth';

//setting headers of all api calls
const headers = {
  'Content-Type': 'application/json'
};

//===================================USER LOGGING IN===================================//

//------FUNCTIONAL------//
export const loginFetch = async (requestData) => {

    //data for api logging
    const endpoint = "/auth/login"
    const method = "POST"

    try{

        //CREATE INITIAL LOG//
        await createLog({
            endpoint:endpoint,
            method:method,
            type:"SENDING API FETCH",
            request_body:requestData.email
        })

        //sending fetch request
        const requestSent = await axios.post(`${API_URL}/login`, requestData, {headers})
    
        //if checking for succesful response
        if(requestSent.status === 200){
            const expirationInSeconds = 3600
            const expirationDate = new Date();
            expirationDate.setTime(expirationDate.getTime() + (expirationInSeconds * 1000));
        
            const serverResponse = {
                data:requestSent.data.data.access_token,
                message:requestSent.data.message,
                expiration:expirationDate
            }

            //CREATE SUCCESS LOG//
            await createLog({
                endpoint:endpoint,
                method:method,
                type:"FETCH RESPONSE",
                response_data:serverResponse,
            })

            //returning data
            return serverResponse
        }

    }catch(err){

        //if checking that proper error data was recieved
        if (err.response && err.response.data) {
            const message = err.response.data.message
            const serverResponse = {
                message:message
            }

            //CREATE ERROR LOG//
            await createErrorLog({
                endpoint:endpoint,
                method:method,
                type:"FETCH ERROR",
                error_data:serverResponse,
            })

            return serverResponse
        } 
    }
}

//===================================REGISTERING NEW USER===================================//

//------FUNCTIONAL------//
export const registerFetch = async (requestData) => {
    
    //data for api logging
    const endpoint = "/auth/register"
    const method = "POST"

    try{

        //CREATE INITIAL LOG//
        await createLog({
            endpoint:endpoint,
            method:method,
            type:"SENDING API FETCH",
            request_body:requestData
        })

        //sending fetch request
        const requestSent = await axios.post(`${API_URL}/register`, requestData, {headers})

        //if checking for a success response
        if(requestSent.status === 201){
            const serverResponse = {
              data:requestSent.status,
              message:requestSent.data.message,
            }

            //CREATE SUCCESS LOG//
            await createLog({
                endpoint:endpoint,
                method:method,
                type:"FETCH RESPONSE",
                response_data:serverResponse,
            })
      
            return serverResponse
        }

    }catch(error){
        //if checking that proper error data was recieved
        if (error.response && error.response.data) {
            const message = error.response.data.message
            const serverResponse = {
                message:message
            }

            //CREATE ERROR LOG//
            await createErrorLog({
                endpoint:endpoint,
                method:method,
                type:"FETCH ERROR",
                error_data:serverResponse,
            })

            return serverResponse
          } 
    }
}


//===================================SUBMITTING A REGISTRATION CODE===================================//

//------FUNCTIONAL------//
export const submitRegisterCode = async (requestData) => {

    //data for api logging
    const endpoint = "/auth/validate-code"
    const method = "GET"
    
    try{

        //CREATE INITIAL LOG//
        await createLog({
            endpoint:endpoint,
            method:method,
            type:"SENDING API FETCH",
            request_query:requestData
        })

        //sending fetch request
        const requestSent = await axios.get(`${API_URL}/validate-code?register_code=${requestData}`, {headers})
    
        //if checking for succesful response
        if(requestSent.status === 200){
            const serverResponse = {
                data:requestSent.data
            }

            //CREATE SUCCESS LOG//
            await createLog({
                endpoint:endpoint,
                method:method,
                type:"FETCH RESPONSE",
                response_data:serverResponse,
            })

            //returning data
            return serverResponse

            
        }else{
            const errorMessage = "bad request"
            return errorMessage
        }

    }catch(error){

        //if checking that proper error data was recieved
        if (error.response && error.response.data) {
            const message = error.response.data.message
            const serverResponse = {
                message:message
            }

            //CREATE ERROR LOG//
            await createErrorLog({
                endpoint:endpoint,
                method:method,
                type:"FETCH ERROR",
                error_data:serverResponse,
            })

            return serverResponse
          } 
    }
}
