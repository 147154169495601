//importing react tools
import React, { useState, useEffect } from 'react'

//Bootstrap tool for styling divs
import Container from 'react-bootstrap/Container';
//Bootstrap tool used for creating nav bar components
import Nav from 'react-bootstrap/Nav';
//Boostrap tool used to create top of page navigation bars
import Navbar from 'react-bootstrap/Navbar';
//React Bootstrap tool used for components that are hidden
//from view until toggled
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Row } from "react-bootstrap";

//importing ability to navigate
import { useNavigate } from "react-router";

//font awesome icons used
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//importing users information and options panel
import FormMenu from './FormMenu';
//importing panel to track mecanics location
import TechTracker from './TechTracker';
//importing company logo
import Logo from '../Logo';

//importing usericon picure used in the account sidepanel
import baseUserIcon from "../../images/user-icon.png"

//importing ability to use browser cookies
import { useCookies } from "react-cookie";

//importing fetch to get users account information
import { retrieveUserInformation } from "../../fetchRequests/accountPageFetch.js";

//importing shared styling functions
import {
    failToast,
  } from "../../shared/sharedFunctions.js"

export default function Header({workOrderData}) {
    //ability to navigate
    const navigate = useNavigate();
    //ability to use browser cookie
    const [cookie, setCookie, removeCookie] = useCookies();

    //use state defining if the sidepanel is shown or not
    const [showPanel, setShowPanel] = useState(false)
    //use state to contain users account information
    const [userData, setUserData] = useState({
        name:"",
        user_name:"",
        phone:"",
        email:"",
        account_plan:"",
        bike:{
          brand:"",
          model:"",
        }
      })


  //===================================GETTING USER ACCOUNT INFORMATION===================================//
     
      const getUserInformation = async () => {

        //calling function to send fetch and get users account information
        const serverResponse = await retrieveUserInformation(cookie.accessToken)
    
    
        if(!serverResponse.data){
          failToast(serverResponse.message)
          //navigate("/")
          return
        }
    
        //parsing user account data
        const dataFromFetch = serverResponse.data.user_data
        const {name, user_name, phone, email, account_plan, bike} = dataFromFetch

        //updating use state to contain user account data
        setUserData({
        name:name,
        user_name:user_name,
        phone:phone,
        email:email,
        account_plan:account_plan,
            bike:{
            brand:bike.brand,
            model:bike.model,
        }
        })
      }
      
      useEffect(() =>{
        getUserInformation()
      }, [])

  //===================================COMPONENT FUNCTIONS===================================//

    const handleLogout = () => {
        removeCookie("token");
        navigate("/")
    }

  //===================================JSX RETURN===================================//

return (
    <>
    {['md'].map((expand) => (
        <Navbar key={expand} expand={expand} bg="dark" data-bs-theme="dark" className="fixed-top pb-4" style={{ zIndex: 1000 }} >
            <Container fluid>
                {/*  The brand containes the logo and seems to act as a container that reacts to the size of the screen */}
                <Navbar.Brand href="#" className="w-100 d-flex justify-content-center justify-content-sm-start align-items-center ">
                    <Logo style={{width: '100px'}}/>
                </Navbar.Brand>
                {/* this toggle only displays when there is not enough space for the Nav.Link elements, displaying a toggle button instead */}
                <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} onClick={() => {setShowPanel(true) }} on className="ms-auto" />
                {/* the offcanvas section defines what is hidden off canvas when their is not enough space to be displayed on screen */}
                <Navbar.Offcanvas
                    id={`offcanvasNavbar-expand-${expand}`}
                    aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                    placement="end"
                    bg="dark" data-bs-theme="dark"
                    show={showPanel}
                >
                        <Offcanvas.Header>
                        <div style={{display:"flex", flexDirection:"column"}}>
                            <div className="desktopSidebarCloseButton">
                                <FontAwesomeIcon
                                icon={faTimes}
                                className="sidebarCloseButton"
                                onClick={() => {
                                    setShowPanel(false);
                                }}
                                />
                            </div>
                            <div className="desktopSidebarUsernameContainer">
                                <div>
                                <div className="sidebarUserIconContainer">
                                    <img
                                    src={baseUserIcon}
                                    className="sidebarUserIcon"
                                    alt="user icon"
                                    ></img>
                                </div>
                                </div>
                                <div className="sidebarUsernameColumn">
                                <Row className="poppins-extralight">{userData.name}</Row>
                                <Row className="poppins-medium">{userData.user_name}</Row>
                                </div>
                            </div> 
                        </div>  
                        </Offcanvas.Header>
                        <Offcanvas.Body className="p-0">
                            <Nav className="justify-content-end flex-grow-1 pe-3 ps-4 pt-4">
                                <Nav.Link onClick={() =>{navigate("/account-page")}} className='desktopOptionsText'>Account</Nav.Link>
                                <Nav.Link onClick={() =>{console.log("navigate history page")}} className='desktopOptionsText'>History</Nav.Link>
                                <Nav.Link onClick={() =>{setShowPanel(true)}} className='desktopOptionsText'>About</Nav.Link>
                                <Nav.Link onClick={() =>{handleLogout()}} className='desktopOptionsText'>Logout</Nav.Link>
                            </Nav>
                            {/* hiding the imported elements unless the toggle has been clicked setting showpanel to true */}
                            {showPanel && (
                            <>
                                <div>
                                    <FormMenu userData={userData}></FormMenu>
                                    <TechTracker workOrderData={workOrderData}></TechTracker>  
                                </div>
                                </>
                                )}
                        </Offcanvas.Body>
                </Navbar.Offcanvas>
            </Container>
        </Navbar>
    ))}
    </>
);
}

