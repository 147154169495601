import React, { useState, useEffect } from 'react'

//importing stylesheet
import '../../stylesheets/main.scss';

//importing ability to navigate
import { useNavigate } from "react-router";
import { Link } from 'react-router-dom';

//Node package that allows for setting cookies in the browser
import { useCookies } from "react-cookie";

//importing required images
import background from "../../images/palmtreesdark.png"
import LoadingModal from '../Modals/LoadingModal.jsx';

//importing child components
import Logo from '../Logo';

//importing styling functions for buttons
import {
    handleMouseOver,
    handleMouseOut,
    failToast,
    successToast
} from "../../shared/sharedFunctions"

//importing fetch function to make a login request
import { loginFetch } from '../../fetchRequests/loginFetch.js';

export default function Login() {

    //ability to navigate
    const navigate = useNavigate();

    //ability to use cookies
    const [cookie, setCookie, removeCookie] = useCookies();

    //use state to hold the behavior of the initial icon
    const [initialClick, setInitialClick] = useState(false)

    //use state to determine whether to show the loading modal
    const [loading, setLoading] = useState(false)
  
    //use state holding the values for the login form
    const [loginForm, setLoginForm] = useState(
        {
          email:null,
          password:null,
        }
    )


    //function to update login form
    const updateForm = (value) => {
        return setLoginForm((prev) => {
          return {...prev, ...value}
        })
    }

    //parsing session id cookie
    const sessionId = cookie.session_token

//===================================SENDING USER LOGIN REQUEST===================================//

    const confirmSubmission = async (e) => {

        e.preventDefault();
        
        setLoading(true)

        if (!loginForm.email) {
            failToast("Email is required");
            setLoading(false)
            return;
        }

        if(!loginForm.password){
            failToast("Password is required");
            setLoading(false)
            return;
        }

        //defining a variable with values entered in the input fields of the HTML
        const userLogin = {
            email: loginForm.email,
            password: loginForm.password,
            session_token:sessionId
        };

        //calling function to send fetch request containing users login
        //information
        const loginAttempt = await loginFetch(userLogin)

        setLoading(false)

        if(!loginAttempt.data){
            failToast(loginAttempt.message)
            return
        }

        //setting a browser cookie to the access token the server returned
        setCookie('accessToken', loginAttempt.data, {
        path: '/',
        expires: loginAttempt.expiration,
        secure: true,
        sameSite: 'none',
        });

        navigate("/home")
        return 
    };

//===================================JSX RETURN===================================//

  return (
    <div className="splash-screen d-flex justify-content-center align-items-center" style = {{backgroundImage:`url(${background})`}}>
        
        {!initialClick ? (
            <div>
                <Link onClick={() => setInitialClick(true)}>
                    <Logo />
                </Link>
                <div 
                    className="poppins-extrabold-italic"
                    style={{
                        color:"white",
                        marginTop:"15px"
                    }}>
                        St.Pete mobile bike repair, tune up, and detail service
                </div>
                <div 
                    className="poppins-regular-italic"
                    style={{
                        color:"#FFAD46",
                        marginTop:"5px"
                    }}>
                        A.A.A service for your bike
                </div>
            </div>
        ) : (
            
        <div className='loginPageContent'>
            <Logo style={{width: '100px'}} />
            {loading ? (
                <div>
                    <LoadingModal message={"Logging you in..."}/>
                </div>
            ) : (
                <>
                    <form onSubmit={confirmSubmission} autoComplete="off" style={{width:"100%", maxWidth:"350px"}}>
                        <div className="form w-100 d-grid gap-3 p-4" style={{maxWidth:"350px"}}>
                            <div className="">
                                <input
                                    id="email"
                                    className="form-control"
                                    type="email"
                                    placeholder='Email'
                                    autoComplete='new-email'
                                    value={loginForm.email}
                                    onChange={(e) => updateForm({email: e.target.value})}
                                    required
                                    >
                                </input>
                            </div>
                            <div className="">
                                <input
                                    id="password"
                                    className="form-control"
                                    type="password"
                                    placeholder='Password'
                                    autoComplete='new-password'
                                    value={loginForm.password}
                                    onChange={(e) => updateForm({password: e.target.value})}
                                    required
                                    >
                                </input>
                            </div>
                            <button
                                className="btn btn-primary"
                                type="submit"
                                onMouseOver={handleMouseOver}
                                onMouseOut={handleMouseOut}
                                >Login
                            </button>
                        </div>
                    </form>
                    <div className="text-center">
                        <p className='registerText poppins-medium'>Don't have an account?</p>
                        <Link 
                            to="/register" 
                            className='registerText poppins-medium'>
                                Sign up
                        </Link>
                    </div>  
                </>        
            )}
        </div>
        )}
    </div>
)
}