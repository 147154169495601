//importing axios to make fetch request
import axios from "axios";

//importing function to create a data log
import { getLog } from "../shared/loggingFunctions";

//setting the base endpoint to send fetch api's too
const API_URL = 'https://bike911-server.onrender.com/log';

//setting the headers for all fetches
const headers = {
    'Content-Type': 'application/json'
  };


//===================================CREATING NEW LOGGING SESSION===================================//

export const initializeLoggerFetch = async () => {

    //creating data to make an initial session log object
    const initialData = await getLog({
        endpoint:"/log/init",
        type:"SENDING API",
        method:"POST"
    })

    try{

        //sending fetch request
        const requestSent = await axios.post(`${API_URL}/init`, initialData, {headers})
    
        //if statement looking for a succesful server response
        if(requestSent.status === 200){
            const serverResponse = {
              data:requestSent.data.data,
              message:requestSent.data.message,
            }
    
            return serverResponse
        }
    
    }catch(error){

        //if statement checking for proper error message
        if (error.response && error.response.data) {
            const message = error.response.data.message
            const serverResponse = {
                message:message
            }
    
            return serverResponse
          } 
    }
}



//===================================SENDING NEW LOG TO LOG SESSION===================================//

export const sendLogToServer = async (newLog, sessionId) => {

    try{

        //sending fetch request
        const requestSent = await axios.post(`${API_URL}/?token=${sessionId}`, newLog, {headers})

    }catch(error){

        //if checking that proper error data was recieved
        if (error.response && error.response.data) {
            const message = error.response.data.message
            const serverResponse = {
                message:message
            }
    
            return serverResponse
        } 
    }
}
