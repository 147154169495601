import React from "react";

//importing child components
import Map from "./Map";
import Header from '../HeaderComponents/Header';
import Footer from './Footer';

//Container for home page nested components
export default function Home({ 
    workOrderData, 
    setWorkOrderData, 
    userLocation,
    usersUpdatedLocation }) {
  
    return (
        <>
            <Header workOrderData={workOrderData}></Header>
            <Map
                workOrderData={workOrderData}
                setWorkOrderData={setWorkOrderData}
                userLocation={userLocation}
                usersUpdatedLocation={usersUpdatedLocation}
            >
            </Map>
            <Footer></Footer>
        </>
    )
}
